import Modular from 'modujs';
import * as modules from './modules';

(function (doc) {
  
  
  
  
  const app = new Modular({ modules });
  
 // console.log(app);
  
  
  function init() {
    app.init(app);

    document.documentElement.classList.add('doc-loaded', 'all-loaded')

  }


  doc.addEventListener('DOMContentLoaded', init);
  
})(document);

import { module as Md } from 'modujs';
import {  gsap, Observer } from '../utils';
import select from 'dom-select';

export default class extends Md {
  
  constructor(m) {
    super(m);

    this.observer = new Observer({
        rootMargin: '-10px',
    });

    this.trigger = this.$('trigger')[0]
    this.logo = this.$('logo')[0]
    this.wrap = this.$('wrap')[0]
    this.eye = this.$('eye')
    this.popup = this.$('popup')[0]

    this.events = {
      click: {
          over: 'showPopup',
          popup: 'hidePopup'
      },
      mouseenter: {
         over: 'showPopup'
      },
      mouseleave: {
         over: 'hidePopup'
      }
    }

  }

  showPopup(){
  //  console.log('show');
    this.el.classList.add('has-popup')
    
    
  }
  hidePopup(){
    this.el.classList.remove('has-popup')
  //  console.log('hide');
  }

  init() {

    const tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.trigger,
            start: "top top",
            end: "bottom top",
            scrub: .2,
           toggleActions: "play reverse play reverse",
           onLeave: () => document.body.classList.remove('menu-nologo'),
           onEnterBack: () => document.body.classList.add('menu-nologo')
        }
      });


      let mm = gsap.matchMedia();

        // mm.add("(min-width: 737px)", () => {
        //   tl.to(this.logo, {
        //     scale: .67,
        //     duration: 1,
        //     ease: "linear"
        //   }, 0)
        //   tl.to(this.wrap, {
        //    yPercent: -100,
        //     duration: 1,
        //     ease: "linear",
        //   }, 0)
        // });

        // mm.add("(max-width: 736px)", () => {
        //   tl.to(this.logo, {
        //     scale: .67,
        //     duration: 1,
        //    ease: "linear",
        //   }, 0)
        //   tl.to(this.wrap, {
        //    yPercent: -140,
        //     duration: 1,
        //    ease: "linear",
        //   }, 0)
        // });
 
      


      this.eye.forEach(element => {
        gsap.fromTo(element, { y: -21},{
          y: 0,
         // yoyo: true,
          repeat: -1,
          duration: .4,
          ease: 'linear', 
          repeatDelay: 2
        })
      });
      
  }

  


  resize() {
    
  }
}
import { module as Md } from 'modujs';
import {  gsap, Observer } from '../utils';
import select from 'dom-select';

export default class extends Md {
  
  constructor(m) {
    super(m);

    this.form = document.getElementById('formular')

    this.events = {
        submit: {
            form: 'handleSubmit'
        }
    }
    

    
  }


  init() {

    
      
  }


  handleSubmit(event){
    event.preventDefault();

      const myForm = document.getElementById('formular');
      const formData = new FormData(myForm);

     if(this.validate_form()){

      this.el.classList.add('is-sending')
      fetch("/", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: new URLSearchParams(formData).toString(),
        })
        .then((e) => this.formsent(e))
        .catch((error) => this.error(error));
      }
      else {
            this.$('error')[0].classList.add('is-visible')
      }
    }

  validate_form(){

    const recaptcha_box_checked = (grecaptcha.getResponse()) ? true : false;

    if (recaptcha_box_checked) { 
        return true;
    }
    else {
        return false;
    }
}

  formsent(e) {
   

    if (e.ok) {
      this.form.reset();
      this.el.classList.remove('is-sending')
      this.el.classList.add('is-sent')
      this.$('error')[0].classList.remove('is-visible')
    } else {
      this.error('Email failed to send')

    }

  }

  error(error) {
    this.el.classList.remove('is-sending')
    this.el.classList.add('is-error')
  }

 

  resize() {
    
  }
}
import { module as Md } from 'modujs';
import {  gsap, Observer, ScrollTrigger} from '../utils';
import select from 'dom-select';

export default class extends Md {
  
  constructor(m) {
    super(m);

    this.flower = this.$('flower')[0]
    this.laptop = this.$('laptop')[0]
    this.laptoptrigger = this.$('laptoptrigger')[0]

    this.images = this.$('images')[0]
    this.phone = this.$('phone')

    this.record = this.$('record')[0]
    this.recordtrigger = this.$('recordtrigger')[0]

    this.time = this.$('time')[0]
    this.timetrigger = this.$('timetrigger')[0]
    this.what = this.$('what')[0]
    this.whattrigger = this.$('whattrigger')[0]

    this.evidence = this.$('evident')[0]
    this.evidencetrigger = this.$('evidenttrigger')[0]

    this.journey = this.$('journey')[0]
    this.journeytrigger = this.$('journeytrigger')[0]

    this.effort = this.$('effort')[0]
    this.handtrigger = this.$('handtrigger')[0]

    this.observer = new Observer({
        rootMargin: '-10px',
    });

    this.index = 0
    

  }

  init() {

    let mm = gsap.matchMedia();
    this.bindedchange = this.change.bind(this)
    this.delayedCall = gsap.delayedCall(1, this.bindedchange)

    mm.add("(min-width: 737px)", () => {
        gsap.fromTo(this.effort, {
            yPercent: 50,
        },{
            yPercent: -20,
            scrollTrigger: {
                trigger: this.handtrigger,
                start: "top bottom",
                end: "bottom top",
                
                scrub: .2,
    
            }
        })
    
        gsap.to(this.record,{
            yPercent: 30,
            scrollTrigger: {
                trigger: this.recordtrigger,
                start: "top bottom",
                end: "bottom top",
                
                scrub: .2,
    
            }
        })
    
       
    
        gsap.to(this.journey,{
            yPercent: 20,
            scrollTrigger: {
                trigger: this.journeytrigger,
                start: "top bottom",
                end: "bottom top",
                
                scrub: .2,
    
            }
        })
    
        gsap.to(this.time,{
            yPercent: 20,
            scrollTrigger: {
                trigger: this.timetrigger,
                start: "top bottom",
                end: "bottom top",
                
                scrub: .2,
    
            }
        })
    
        gsap.to(this.what,{
            yPercent: 20,
            scrollTrigger: {
                trigger: this.whattrigger,
                start: "top bottom",
                end: "bottom top",
                scrub: .2,
    
            }
        })
    });

    
    gsap.fromTo(this.evidence, {
        yPercent: 20,
    },{
        yPercent: -20,
        scrollTrigger: {
            trigger: this.evidencetrigger,
            start: "bottom bottom",
            end: "bottom top",
           
            scrub: .2,

        }
    })

    // ScrollTrigger.create({
     
    //     trigger:  this.images, 
    //     start: 'top bottom',
    //     end: 'bottom top',
    //     scrub: .5, 
     
    //     onUpdate: (self)=> {   

    //         // console.log(Math.ceil(self.progress * 10));
            
    //         // const index  = (Math.ceil(self.progress * 10)) % 3;         
    //         // this.images.style.setProperty('--pos',  index);
    //         // this.phone.forEach(element => {
    //         //     element.style.opacity = 0
    //         // });
    //         // this.phone[index].style.opacity = 1
    //     }

    // })

   


    gsap.to(this.flower, {
        rotate: 360,
        scrollTrigger: {
            trigger: this.flower,
            start: "top bottom",
            end: "bottom top",
            scrub: .2,

        }
    })

    gsap.fromTo(this.laptop, {
        yPercent: 20,
    },{
        yPercent: -20,
        scrollTrigger: {
            trigger: this.laptoptrigger,
            start: "bottom bottom",
            end: "bottom top",
           
            scrub: .2,

        }
    })

 

    // const tl = gsap.timeline({
    //     scrollTrigger: {
    //       trigger: this.trigger,
    //         start: "top top",
    //         end: "bottom top",
    //         scrub: .2,
    //        toggleActions: "play reverse play reverse",
    //        onLeave: () => document.body.classList.remove('menu-nologo'),
    //        onEnterBack: () => document.body.classList.add('menu-nologo')
    //     }
    //   });
 
    //   tl.to(this.logo, {
    //     scale: .67,
    //     duration: 1,
    //    // ease: "power2.inOut"
    //   }, 0)
    //   tl.to(this.wrap, {
    //    yPercent: -100,
    //     duration: 1,
    //    // ease: "power2.inOut",
    //   }, 0)
      
  }

  change(){

    this.phone.forEach(element => {
                element.style.opacity = 0
    });

   // console.log(this.index);
    
   
    this.phone[gsap.utils.wrap(0 , 4, this.index++ )].style.opacity = 1

    this.delayedCall = gsap.delayedCall(1.5, this.bindedchange)
  }


  resize() {
    
  }
}
import { module as Md } from 'modujs';
import {  cl, gsap, Observer } from '../utils';
import select from 'dom-select';

export default class extends Md {
  
  constructor(m) {
    super(m);

    this.container = this.$('container')[0]
    this.close = this.$('close')
    

    this.events = {
      click: {
          close: 'closePlayer'
      }
    }
  }

  init() {

    
  }

  playVideo(e){
    

  }

  openPlayer(film){

   // console.log(film);
    
    let template = (video)=> `<iframe src="https://player.vimeo.com/video/${video}?autoplay=1&amp;app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen=""></iframe>`


    this.el.classList.add('is-active')
    const video = template(film)
    this.container.innerHTML = video

  }

  closePlayer(){
    this.el.classList.remove('is-active')
    this.container.innerHTML = ''
  }

  resize() {
    
  }
  

  
}
import { module as Md } from 'modujs';
import Flickity from 'flickity';

export default class extends Md {
  
  constructor(m) {
    super(m);

    
  }

  init() {

    var flkty = new Flickity( this.el, {
        // options
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        pageDots: false,
        watchCSS: true
      });
      
      
  }

  
  resize() {
    
  }
}
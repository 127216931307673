import { module as Md } from 'modujs';
import {  cl, gsap, Observer } from '../utils';
import select from 'dom-select';

export default class extends Md {
  
  constructor(m) {
    super(m);

    this.video = this.$('video')[0]
    this.playbut = this.$('play')[0]
    this.video && this.video.pause()

    this.observer = new Observer({
        rootMargin: '-10px',
        callback: (visible, target, entry) => {
            if (visible) {
                this.video && this.play()
                //target && target.play()
            } else {
                //target && target.pause()
                this.video && this.stop()
            }
        }
    });

    this.events = {
      click: {
          play: 'playVideo'
      }
    }
  }

  init() {

    this.video && this.observer.add(this.video)

      
  }

  playVideo(e){
    e.preventDefault()
    this.call('openPlayer', e.currentTarget.dataset.film , 'player')
  }

  play(){
    this.video.play()
    //console.log('play');
    
  }

  stop(){
    this.video.pause()
    //console.log('pause');
  }

  resize() {
    
  }
}

  
function getScrollbarWidth() {
    var outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    document.body.appendChild(outer);
    var widthNoScroll = outer.offsetWidth;
    outer.style.overflow = 'scroll';
    var inner = document.createElement('div');
    inner.style.width = '100%';
    inner.style.opacity = '0';
    outer.appendChild(inner);
    var widthWithScroll = inner.offsetWidth;
    outer.parentNode?.removeChild(outer);
    return widthNoScroll - widthWithScroll;
  }
  
  // const scrollLock = function(el: HTMLElement, callback?: (scGap: number) => void) {
  //   const scGap = getScrollbarWidth();
  //   (window as any).lockedEl = el
  //   disableBodyScroll(el)
  //   if (scGap) {
  //     document.body.style.setProperty('--scroll-gap', scGap+'px')
  //   }
  //
  //   if (callback) {
  //     callback(scGap)
  //   }
  // }
  //
  // const scrollUnLock = function(callback?: () => void) {
  //   if (window.lockedEl) {
  //     enableBodyScroll(window.lockedEl)
  //
  //     document.body.style.removeProperty('--scroll-gap')
  //
  //     if (callback) {
  //       callback()
  //     }
  //   }
  // }
  
  export { getScrollbarWidth };
  
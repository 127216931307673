import { module as Md } from 'modujs';
import { getScrollbarWidth, gsap, ScrollTrigger } from '../utils';
import select from 'dom-select';

export default class extends Md {
  locked = false;


  constructor(m) {
    super(m);
    //this.locked = true
    
  }

  init() {

    
    import('lenis').then(({ default: Lenis }) => {
      
      
      const lenis = new Lenis({lerp: 0});
      this.lenis = lenis
      lenis.on('scroll', ScrollTrigger.update);

      if (this.locked) {
       // this.lockScroll()
      }


      //requestAnimationFrame(this.raf.bind(this))

      gsap.ticker.add((time) => {
        lenis.raf(time * 1000); // Convert time from seconds to milliseconds
      });
      gsap.ticker.lagSmoothing(0);

     
      document.querySelectorAll('a[href^="#"]').forEach((el) => {
        
        
        el.addEventListener('click', (e) => {
          e.preventDefault()
          const id = el.getAttribute('href')?.slice(1)
          if (!id) return
          console.log(id);
          const target = document.getElementById(id)
          if (target) {
            this.lenis.scrollTo(target, {
              offset: 0,
              lock: true,
              duration: .75,
            })
          }
        })
      });
    }).catch();
  }

  raf(time) {
    this.lenis.raf(time)
    requestAnimationFrame(this.raf.bind(this))
  }



  lockScroll() {
    this.locked = true;
    const scGap = getScrollbarWidth();

    if (scGap) {
      document.body.style.setProperty('--scroll-gap', scGap + 'px');
    }

    if (this.lenis) {
      this.lenis.stop();
    }

   // this.updateParallaxes();
  }

  unlockScroll() {
    this.locked = false;
    document.body.style.removeProperty('--scroll-gap');
    if (this.lenis) {
      this.lenis.start();
    }

   // this.updateParallaxes();
  }

  goTo(section) {
    if (this.lenis) {
      //console.log(`[data-section-title="${section}"]`);
      
      this.lenis.scrollTo(`[data-section-title="${section}"]`);
    }
  }

 

  destroy() {
    if (this.lenis) {
      this.lenis.destroy();
      ScrollTrigger?.getAll().forEach((tr) => {
        tr.kill(true)
      });
    }
  }
}

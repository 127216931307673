import { module as Md } from 'modujs';
import {  Observer } from '../utils';
import {  gsap, ScrollTrigger } from '../utils';


export default class extends Md {

  constructor(m) {
    super(m);

 

    this.observer = new Observer({
        rootMargin: '-10px'
    });

    

    
  }

  init() {


    this.observer.add('.trusted__item, .circle, .accr__item, .member')


  
  }

  update(arg){
    
    
  }


  destroy() {
    
  }
}
